import MainView from "../views/MainView.vue"

export const routes = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
    children: [
      {
        path: "/oauth",
        name: "AuthOauthView",
        component: () => import("../views/AuthOauthView.vue"),
        children: [
          // 3rd party auth (eg. instagram basic api)
          {
            path: "instagram-basic-display",
            name: "AuthInstagramBasicDisplayView",
            component: () => import("../views/AuthInstagramBasicDisplayView.vue"),
          },
        ],
      },

      // sites
      {
        path: "sites",
        name: "SitesView",
        component: () => import("../views/SitesView.vue"),
        meta: {
          pageTitleKey: "view-title-sites",
        },
      },

      // site
      {
        path: "site/:siteName",
        component: () => import("../views/SiteView.vue"),
        children: [
          {
            path: "",
            name: "SiteMainView",
            component: () => import("../views/SiteMainView.vue"),
          },

          {
            path: "settings",
            name: "SiteSettingsView",
            component: () => import("../views/SiteSettingsView.vue"),
            meta: {
              pageTitleKey: "view-title-site-settings",
            },
            children: [
              {
                path: "sections",
                name: "SiteSettingsSectionsView",
                component: () => import("../views/SiteSettingsSectionsView.vue"),
                meta: {
                  pageTitleKey: "view-title-site-settings-sections",
                },
              },
            ],
          },

          // pages
          {
            path: "pages",
            name: "SitePagesView",
            component: () => import("../views/SitePagesView.vue"),
            meta: {
              pageTitleKey: "view-title-pages",
            },
          },
          {
            path: "page/:pageId/editor",
            name: "EditorView",
            component: () => import("../views/EditorView.vue"),
          },
          {
            path: "page/:pageId/settings",
            name: "SitePageSettingsView",
            component: () => import("../views/SitePageSettingsView.vue"),
            meta: {
              pageTitleKey: "view-title-page-settings",
            },
          },

          // shop
          {
            path: "commerce",
            name: "ShopView",
            component: () => import("../views/ShopView.vue"),
            children: [
              {
                path: "",
                name: "ShopMenuView",
                component: () => import("../views/ShopMenuView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce",
                },
              },
              {
                path: "orders",
                name: "ShopOrdersView",
                component: () => import("../views/ShopOrdersView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-orders",
                },
                children: [
                  {
                    path: ":orderId",
                    name: "ShopOrderDetailView",
                    component: () => import("../views/ShopOrderDetailView.vue"),
                  },
                ],
              },
              {
                path: "inventory",
                name: "ShopInventoryView",
                component: () => import("../views/ShopInventoryView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-inventory",
                },
                children: [
                  {
                    path: ":itemId",
                    name: "ShopInventoryItemDetailView",
                    component: () => import("../views/ShopInventoryItemDetailView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-inventory-product",
                    },
                  },
                ],
              },

              {
                path: "settings",
                name: "ShopSettingsView",
                component: () => import("../views/ShopSettingsView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-settings",
                },
                children: [
                  {
                    path: "general",
                    name: "ShopSettingsGeneralView",
                    component: () => import("../views/ShopSettingsGeneralView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-settings-general",
                    },
                  },
                  {
                    path: "invoicing",
                    name: "ShopSettingsInvoicingView",
                    component: () => import("../views/ShopSettingsInvoicingView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-settings-invoicing",
                    },
                  },
                  {
                    path: "payment",
                    name: "ShopSettingsPaymentView",
                    component: () => import("../views/ShopSettingsPaymentView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-settings-payment",
                    },
                  },
                  {
                    path: "delivery",
                    name: "ShopSettingsDeliveryView",
                    component: () => import("../views/ShopSettingsDeliveryView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-settings-delivery",
                    },
                    children: [
                      {
                        path: ":id",
                        name: "ShopSettingsDeliveryMethodView",
                        component: () => import("../views/ShopSettingsDeliveryMethodView.vue"),
                        meta: {
                          pageTitleKey: "view-title-commerce-settings-delivery-method",
                        },
                      },
                    ],
                  },
                  {
                    path: "cart",
                    name: "ShopSettingsCartView",
                    component: () => import("../views/ShopSettingsCartView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-settings-cart",
                    },
                  },
                ],
              },

              {
                path: "flow",
                name: "ShopFlowView",
                component: () => import("../views/ShopFlowView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-order-flow",
                },
              },
              {
                path: "promocodes",
                name: "ShopPromoCodesView",
                component: () => import("../views/ShopPromoCodesView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-promocodes",
                },
                children: [
                  {
                    path: "import",
                    name: "ShopPromoCodeImportView",
                    component: () => import("../views/ShopPromoCodeImportView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-promocode-import",
                    },
                  },
                  {
                    path: ":codeId",
                    name: "ShopPromoCodeDetailView",
                    component: () => import("../views/ShopPromoCodeDetailView.vue"),
                    meta: {
                      pageTitleKey: "view-title-commerce-promocode-detail",
                    },
                  },
                ],
              },
              {
                path: "deliveryplanning",
                name: "ShopDeliveryPlanningView",
                component: () => import("../views/ShopDeliveryPlanningView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-delivery-planning",
                },
              },
              {
                path: "feereports",
                name: "ShopFeeReportsView",
                component: () => import("../views/ShopFeeReportsView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-fee-reports",
                },
              },
              {
                path: "billing",
                name: "ShopBillingView",
                component: () => import("../views/ShopBillingView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-fee-billing",
                },
              },
              {
                path: "export",
                name: "ShopExportView",
                component: () => import("../views/ShopExportView.vue"),
                meta: {
                  pageTitleKey: "view-title-commerce-export",
                },
              },
            ],
          },
        ],
      },

      // user profile
      {
        path: "user",
        name: "UserView",
        component: () => import("../views/UserView.vue"),
        meta: {
          pageTitleKey: "view-title-profile",
        },
      },
      {
        path: "user/changePassword",
        name: "UserChangePasswordView",
        component: () => import("../views/UserChangePasswordView.vue"),
        meta: {
          pageTitleKey: "view-title-profile-changepassword",
        },
      },
      {
        path: "user/passkeys",
        name: "UserPasskeysView",
        component: () => import("../views/UserPasskeysView.vue"),
        meta: {
          pageTitleKey: "view-title-profile-passkeys",
        },
      },

      {
        path: "user/notifications",
        name: "NotificationsView",
        component: () => import("../views/NotificationsView.vue"),
        meta: {
          pageTitleKey: "view-title-profile-notifications",
        },
      },

      // platform
      {
        path: "platform",
        name: "PlatformView",
        component: () => import("../views/PlatformView.vue"),
        meta: {
          pageTitleKey: "view-title-platform",
        },
        children: [
          {
            path: "clients",
            name: "PlatformClientsView",
            component: () => import("../views/PlatformClientsView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-clients",
            },
            children: [
              {
                path: ":clientId",
                name: "PlatformClientDetailView",
                component: () => import("../views/PlatformClientDetailView.vue"),
              },
            ],
          },
          {
            path: "sites",
            name: "PlatformSitesView",
            component: () => import("../views/PlatformSitesView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-sites",
            },
            children: [
              {
                path: ":siteId",
                name: "PlatformSiteDetailView",
                component: () => import("../views/PlatformSiteDetailView.vue"),
              },
            ],
          },
          {
            path: "users",
            name: "PlatformUsersView",
            component: () => import("../views/PlatformUsersView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-users",
            },
            children: [
              {
                path: "add",
                name: "PlatformUserAddView",
                component: () => import("../views/PlatformUserAddView.vue"),
              },
              {
                path: ":userId",
                name: "PlatformUserDetailView",
                component: () => import("../views/PlatformUserDetailView.vue"),
              },
            ],
          },
          {
            path: "shops",
            name: "PlatformShopsView",
            component: () => import("../views/PlatformShopsView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-shops",
            },
            children: [
              {
                path: ":shopId",
                name: "PlatformShopDetailView",
                component: () => import("../views/PlatformShopDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-platform-shops-detail",
                },
              },
            ],
          },
          {
            path: "events",
            name: "PlatformEventsView",
            component: () => import("../views/PlatformEventsView.vue"),
            meta: {
              pageTitleKey: "view-title-platform-events",
            },
            children: [
              {
                path: ":eventId",
                name: "PlatformEventDetailView",
                component: () => import("../views/PlatformEventDetailView.vue"),
                meta: {
                  pageTitleKey: "view-title-platform-events-detail",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    name: "AuthView",
    component: () => import("../views/AuthView.vue"),
    children: [
      // login (after email link redirect)
      {
        path: "verify",
        name: "AuthVerifyView",
        component: () => import("../views/AuthVerifyView.vue"),
      },
    ],
  },
  {path: "", redirect: {name: "SitesView"}},
  {path: "/:pathMatch(.*)*", redirect: {name: "SitesView"}},
]
