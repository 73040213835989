<template>
  <div>
    <RouterView class="app-main-router-view" />

    <FirebaseMessaging v-if="userLoaded" />
  </div>
</template>

<script>
import FirebaseMessaging from "../components/FirebaseMessaging.vue"
import {useUserStore} from "@/stores/user"
import {storeToRefs} from "pinia"

export default {
  components: {
    FirebaseMessaging,
  },
  setup() {
    // stores
    const userStore = useUserStore()

    // getters
    const {userLoaded} = storeToRefs(userStore)

    return {
      userLoaded,
    }
  },
}
</script>

<style scoped>
#user-information {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  padding: 5px 15px;
}
.lang-option {
  cursor: pointer;
  border: 1px solid #d65252;
  background-color: white;
  padding: 0 3px;
  border-radius: 7px;
  font-size: 12px;
  margin-bottom: 2px;
}
</style>
